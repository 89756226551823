body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  box-sizing: border-box;
  position: relative;
}

.udux {
  text-align: center;
  margin-top: 50px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 50px;
  background-image: url('../src/assets/udux.jpeg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position:cover;
}

.home-image {
  max-width: 100%;
  height: auto;
}

/* Modal styles */
